import { db } from "./firebase";

/*
      });
      console.log(devices);
    })
    .catch((err) => console.log(err));
};*/
/*
db.collection("Devices").get().then(querySnapshot => {
  querySnapshot.forEach(doc => {
      let data = doc.data()
      let keys = Object.keys(data.numbers)
      let parsedData = keys.map(e => {
          return {[e]: parseInt(data.numbers[e])}
      })
      delete data.numbers
      newData = data
      newData.numbers = parsedData
      console.log(newData)
      db.collection("Devices").doc(doc.id).set(newData)
  })
})*/

const getDevices = (userAnswer) => {
  return new Promise((resolve, reject) => {
    db.collection("Devices")
      .where("type", "==", userAnswer.type)
      .where("prezzo", ">=", userAnswer.prezzo[0])
      .where("prezzo", "<=", userAnswer.prezzo[1])
      .limit(2)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.empty ? "ERRORE" : "ESISTONO RISULTATI");
        let devices = querySnapshot.docs.map((doc) => doc.data());
        if (userAnswer.brands.length) {
          devices = devices.filter((e) => userAnswer.brands.includes(e.brand));
        }
        if (!devices.length) {
          return reject("noDevicesInSelectedPriceRange");
        }
        return resolve(devices);
      });
  });
};

const answer = (userAnswer) => {
  return new Promise((resolve, reject) => {
    console.log(userAnswer);
    db.collection("Devices")
      .where("type", "==", userAnswer.type)
      .where("prezzo", "<=", userAnswer.prezzo[1])
      .where("prezzo", ">=", userAnswer.prezzo[0])
      .get()
      .then((querySnapshot) => {
        let devices = querySnapshot.docs.map((doc) => doc.data());
        if (userAnswer.brands.length) {
          devices = devices.filter((e) => userAnswer.brands.includes(e.brand));
        }
        if (!devices.length) {
          reject("noDevicesInSelectedPriceRange");
        }
        let maxPrestazioni = Math.max.apply(
          Math,
          devices.map(function(o) {
            return o.prestazioni;
          })
        );

        let deviceScores = devices.map((device) => {
          let score = device.numbers.reduce((accumulator, prop) => {
            let key = Object.keys(prop)[0];
            let partialScore = Math.abs(userAnswer[key] - prop[key]);
            return (accumulator += partialScore);
          }, 0);
          score += Math.abs(
            userAnswer.prestazioni -
              Math.round((device.prestazioni / maxPrestazioni) * 10)
          );
          device.score = score;
          return device;
        });
        let deviceScoresSorted = deviceScores.sort((a, b) =>
          a.score > b.score ? 1 : b.score > a.score ? -1 : 0
        );
        let podiumDevices = deviceScoresSorted.slice(0, 3);
        resolve(podiumDevices);
      });
  });
};

export { answer, getDevices };
